import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { AuthContext } from '../context/AuthContext';

import './ScanTagPage.css'; // Подключите стили для этого компонента

const ScanTagPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tagId = searchParams.get('tag_id');
  const { accessToken, currentUserId } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [linkSuccess, setLinkSuccess] = useState(false);
  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [sessionDuration, setSessionDuration] = useState(0);

  // Add new state for link operation
  const [linkError, setLinkError] = useState(null);
  // Add new state for reading session
  const [readingSessionId, setReadingSessionId] = useState(null);

  // Локальные состояния
  const [loading, setLoading] = useState(true); // Для лоадера, пока не получим данные о метке
  const [status, setStatus] = useState(null);   // Хранит текущее состояние метки
  const [error, setError] = useState(null);      // Для ошибок бэкенда, если есть

  // Пример перечисления возможных состояний (для наглядности)
  // "unauth"       - пользователь не авторизован
  // "unlinked"     - метка не привязана
  // "linked_noSession"  - метка привязана, нет активной сессии чтения
  // "linked_activeSession" - метка привязана, есть активная сессия чтения
  // "linked_otherUser" - метка привязана другому пользователю
  // "not_found"    - метка не найдена / ошибка

  useEffect(() => {
    // Эмулируем запрос к бэкенду для получения статуса метки:
    // 1. Проверяем авторизацию.
    // 2. Запрашиваем /tags/status?tag_id=XYZ
    // 3. Определяем статус и сохраняем его в state.
    const fetchTagStatus = async () => {
      if (!tagId) {
        setError('ID метки не указан');
        setLoading(false);
        return;
      }

      if (!accessToken) {
        setStatus('unauth');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/v1/nfc/tags/status?tag_id=${tagId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (!response.ok) {
          throw new Error('Ошибка при получении статуса метки');
        }

        const data = await response.json();

        if (!data.tagExists) {
          setStatus('unlinked');
        } else if (!data.linked) {
          setStatus('unlinked');
        } else if (!data.linkedToCurrentUser) {
          setStatus('linked_otherUser');
        } else {
          // Check if there's an active reading session
          const sessionResponse = await fetch(`${apiUrl}/v1/reading/current?tag_id=${tagId}`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });

          if (sessionResponse.ok) {
            const sessionData = await sessionResponse.json();
            // Автоматически отправляем запрос stop/start в зависимости от наличия активной сессии
            if (sessionData.has_active_session) {
              // Если есть активная сессия - останавливаем её
              const stopResponse = await fetch(`${apiUrl}/v1/reading/stop`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ tag_id: tagId })
              });

              if (stopResponse.ok) {
                setStatus('linked_noSession');
                setReadingSessionId(null);
                setSessionStartTime(null);
                setSessionDuration(0);
              }
            } else {
              // Если нет активной сессии - начинаем новую
              const startResponse = await fetch(`${apiUrl}/v1/reading/start`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ tag_id: tagId })
              });

              if (startResponse.ok) {
                const startData = await startResponse.json();
                setReadingSessionId(startData.session_id);
                setStatus('linked_activeSession');
                setSessionStartTime(new Date().toISOString());
                setSessionDuration(0);
              }
            }
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTagStatus();
  }, [tagId, accessToken, apiUrl]);


    
  // Функции-обработчики для кнопок (заглушки)
  const handleLogin = () => {
    // Реальная логика: navigate('/auth/login?next=...' );
    navigate('/auth/login');
  };

  const handleRegister = () => {
    // Реальная логика: navigate('/auth/register?next=...' );
    navigate('/auth/register');
  };

  const handleLinkTag = async () => {
    setLoading(true);
    setLinkError(null);
    try {
      const response = await fetch(`${apiUrl}/v1/nfc/link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ tag_id: tagId })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Ошибка при связывании метки');
      }

      await response.json();
      setStatus('linked_noSession');
      setLinkSuccess(true);
    } catch (err) {
      setLinkError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStartReading = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/v1/reading/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ tag_id: tagId })
      });

      if (!response.ok) {
        throw new Error('Не удалось начать сессию чтения');
      }

      const data = await response.json();
      setReadingSessionId(data.session_id);
      setStatus('linked_activeSession');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStopReading = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/v1/reading/stop`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ tag_id: tagId })
      });

      if (!response.ok) {
        throw new Error('Не удалось остановить сессию чтения');
      }

      // Step 9: Update UI to show reading session ended
      setReadingSessionId(null);
      setSessionStartTime(null);
      setSessionDuration(0);
      setStatus('linked_noSession');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    // Вернуться назад или на главную
    navigate('/');
  };

  // Если идёт загрузка - показываем лоадер
  if (loading) {
    return (
      <>
        <Navbar />
        <main className="scan-tag-page">
          <div className="spinner-container">
            <div className="spinner"></div>
            <p>Загрузка...</p>
          </div>
        </main>
        <Footer />
      </>
    );
  }

  // Если есть общая ошибка (например, ошибка сервера)
  if (error) {
    return (
      <>
        <Navbar />
        <main className="scan-tag-page">
          <div className="error-block">
            <h2>Ошибка</h2>
            <p>Произошла непредвиденная ошибка: {error}</p>
            <button onClick={handleGoBack} className="btn btn-primary">
              На главную
            </button>
          </div>
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
    <div className="scan-page-wrapper">
        <Navbar />
        <main className="scan-tag-page">
          {/* В зависимости от статуса метки показываем нужный блок */}
          {status === 'unauth' && (
            <div className="scan-block unauth-block">
              <h2>Требуется авторизация</h2>
              <p>Чтобы использовать NFC-метку, необходимо войти в систему.</p>
              <div className="auth-buttons">
                <button 
                  onClick={() => navigate(`/auth/login?return_url=${encodeURIComponent(`/scan-tag?tag_id=${tagId}`)}`)} 
                  className="btn btn-primary"
                >
                  Войти
                </button>
                <button 
                  onClick={() => navigate(`/auth/register?return_url=${encodeURIComponent(`/scan-tag?tag_id=${tagId}`)}`)} 
                  className="btn btn-secondary"
                >
                  Зарегистрироваться
                </button>
              </div>
            </div>
          )}
          
          {status === 'unlinked' && !linkSuccess && (
            <div className="scan-block unlinked-block">
              <h2>Новая NFC-метка</h2>
              <p>Вы можете привязать метку к своему аккаунту.</p>
              <button onClick={handleLinkTag} className="btn btn-primary">
                Привязать метку
              </button>
            </div>
          )}

          {status === 'linked_noSession' && linkSuccess && (
            <div className="scan-block success-block">
              <h2>Метка успешно привязана!</h2>
              <p>Метка успешно связана с вашим аккаунтом. Теперь вы можете использовать её для отслеживания чтения.</p>
              <button onClick={handleStartReading} className="btn btn-primary">
                Начать чтение
              </button>
            </div>
          )}

          {status === 'linked_noSession' && !linkSuccess && (
            <div className="scan-block linked-block">
              <h2>Ваша сессия чтения закончилась!</h2>
              <p>Нажмите, чтобы начать новую сессию чтения.</p>
              <button onClick={handleStartReading} className="btn btn-primary">
                Начать чтение
              </button>
            </div>
          )}

          {status === 'linked_activeSession' && (
            <div className="scan-block active-session-block">
              <h2>Ваша сессия чтения начата!</h2>
              <p>Сеанс чтения уже активен. Можете остановить, когда закончите.</p>
              {sessionStartTime && (
              <p>Начало чтения: {new Date(sessionStartTime).toLocaleString()}</p>
              )}
              <p>Продолжительность: {sessionDuration} минут</p>
              <button onClick={handleStopReading} className="btn btn-danger">
                Остановить чтение
              </button>
            </div>
          )}

          {status === 'linked_otherUser' && (
            <div className="scan-block blocked-block">
              <h2>Метка уже занята</h2>
              <p>Данная NFC-метка привязана к другому аккаунту.</p>
              {/* Если бизнес-логика разрешает "отвязать", добавьте кнопку */}
              {/* <button className="btn btn-warning">Запросить отвязку</button> */}
            </div>
          )}

          {status === 'not_found' && (
            <div className="scan-block error-block">
              <h2>Метка не найдена</h2>
              <p>Нет записи о такой метке в системе.</p>
              <button onClick={handleGoBack} className="btn btn-primary">
                Вернуться назад
              </button>
            </div>
          )}
        </main>
        <Footer />
    </div>  
    </>
  );
};

export default ScanTagPage;
