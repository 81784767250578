import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import "./LinkNfcPage.css";


const LinkNfcPage = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("Нажмите кнопку и приложите метку");
  const [tagId, setTagId] = useState(null);
  const { accessToken, authorizedFetch } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Функция для реального сканирования NFC
  const handleScanNFC = async () => {
    if (!("NDEFReader" in window)) {
      setStatus("Ваш браузер не поддерживает Web NFC");
      return;
    }

    try {
      const nfcReader = new window.NDEFReader();
      await nfcReader.scan();
      setStatus("Сканирование NFC...");

      nfcReader.onreading = async (event) => {
        console.log("NFC-событие:", event);

        const tagData = event.message.records[0];
        const decoder = new TextDecoder();
        const tagIdString = decoder.decode(tagData.data);
        setTagId(tagIdString);
        setStatus(`Метка считана: ${tagIdString}`);
      };
    } catch (error) {
      setStatus(`Ошибка NFC: ${error.message}`);
    }
  };

  // 🔹 Добавляем обработчик для эмуляции NFC
  useEffect(() => {
    const handleMockReading = (event) => {
      console.log("Событие чтения NFC (эмуляция):", event);
      if (event.detail && event.detail.message) {
        const tagData = event.detail.message.records[0];
        const decoder = new TextDecoder();
        const tagIdString = decoder.decode(tagData.data);
        setTagId(tagIdString);
        setStatus(`Метка считана: ${tagIdString}`);
      }
    };

    window.addEventListener("reading", handleMockReading);
    return () => window.removeEventListener("reading", handleMockReading);
  }, []);

  return (
    <div className="link-nfc-page">
      <Navbar />
      <div className="nfc-container">
        <h2>Привязка NFC-метки</h2>
        <p>{status}</p>
        <button className="scan-nfc-button" onClick={handleScanNFC}>
          Сканировать метку
        </button>
        {tagId && <p className="nfc-tag-id">Привязанная метка: {tagId}</p>}
      </div>
      <Footer />
    </div>
  );
};

export default LinkNfcPage;
