import React, { useState, useContext } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { decodeToken } from '../../utils/auth';

const Login = ({ returnUrl }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Формируем данные в формате form-urlencoded
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch(`${apiUrl}/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      });

      if (response.ok) {
        // Обработка успешного входа
        const data = await response.json();
        console.log('Вход выполнен успешно');

        // Декодирование токена
        const decoded = decodeToken(data.access_token);
        const userId = decoded.user_id;  // предполагается, что user_id закодирован в токене
        const usernameFromToken = decoded.sub; // поле 'sub' стандартно для username
        console.log('Вход выполнен успешно refresh_token - ', data.refresh_token);

        // Порядок: (accessToken, userId, username, refreshToken)
        login(data.access_token, userId, usernameFromToken, data.refresh_token);

        // // Перенаправляем на страницу пользователя по его ID
        // navigate(`/users/${userId}`);
        // Navigate to return URL if present, otherwise to user profile
        if (returnUrl) {
          navigate(returnUrl);
        } else {
          navigate(`/users/${userId}`);
        }
      } else {
        // Обработка ошибок
        const errorData = await response.json();
        setError(errorData.detail || 'Ошибка входа');
      }
    } catch (err) {
      setError('Ошибка сети, попробуйте еще раз');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="login-form">
          <h1 className="login-title">Вход</h1>
          <form onSubmit={handleLogin}>
            <div className="login-input-group">
              <label>Имя пользователя</label>
              <input
                type="text"
                placeholder="Введите ваше имя пользователя"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="login-input-group">
              <label>Пароль</label>
              <input
                type="password"
                placeholder="Введите ваш пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="login-button">
              Войти
            </button>
          </form>
        </div>
        <div className="login-image">
          <img src="/images/clubs.jpeg" alt="Рабочий стол с ноутбуком" />
        </div>
      </div>
    </div>
  );
};

export default Login;
