import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HeroProfileSection from '../components/profile/HeroProfileSection';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import AboutClub from '../components/common/AboutMe';
import './ProfilePage.css';
import { AuthContext } from '../context/AuthContext';

const UserProfilePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { userId } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  const { accessToken, currentUserId, authorizedFetch } = useContext(AuthContext);

  // Загрузка данных профиля
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!accessToken) return;

      // Функция для получения подписанной ссылки
      const getSignedUrl = async (fileKey) => {
        if (!fileKey) return null; // Если ключ отсутствует, возвращаем null
        try {
          const response = await authorizedFetch(`${apiUrl}/v1/files/signed-url/${fileKey}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          if (!response.ok) {
            throw new Error('Ошибка при получении подписанной ссылки');
          }
          const data = await response.json();
          return data.signed_url; // Возвращаем подписанную ссылку
        } catch (error) {
          console.error('Ошибка при загрузке подписанной ссылки:', error);
          return null;
        }
      };

      try {
        // Загружаем данные профиля
        const response = await authorizedFetch(`${apiUrl}/v1/users/${userId}/profile`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Ошибка при загрузке данных профиля');
        }

        const data = await response.json();

        // Получаем подписанные ссылки для фото
        const avatarUrl = await getSignedUrl(data.photo);
        const bookImageUrl = await getSignedUrl(data.current_reading_photo);

        // Обновляем состояние профиля
        setUserProfile({
          fullName: `${data.first_name || 'Имя'} ${data.last_name || 'Фамилия'}`,
          city: data.city || 'Город не указан',
          avatar: avatarUrl || '/images/placeholder_avatar.png',
          aboutMe: data.about_me || 'Пользователь пока не добавил информацию о себе.',
          currentBook: data.current_reading_title || 'Название книги не указано',
          bookImage: bookImageUrl || '/images/placeholder_book.png',
          bookAuthor: data.current_reading_author || 'Автор не указан',
          bookDescription: data.current_reading_description || 'Описание книги отсутствует.',
        });
      } catch (error) {
        console.error('Ошибка при загрузке данных профиля:', error);
      }
    };

    fetchUserProfile();
  }, [userId, apiUrl, accessToken, authorizedFetch]);

  // Проверка авторизации
  if (!accessToken) {
    return (
      <div className="not-authorized-message">
        Чтобы просматривать профили участников, нужно быть авторизованным пользователем.
      </div>
    );
  }

  // Если профиль ещё не загружен
  if (!userProfile) {
    return <div className="loading">Загрузка...</div>;
  }

  // Определяем, может ли пользователь редактировать профиль
  const canEdit = Number(userId) === currentUserId;

  return (
    <div className="profile-page">
      <Navbar />
      <HeroProfileSection
        fullName={userProfile.fullName}
        city={userProfile.city}
        avatar={userProfile.avatar} // Подписанная ссылка на аватар
      />
      <AboutClub
        clubDescription={userProfile.aboutMe}
        bookImage={userProfile.bookImage} // Подписанная ссылка на фото книги
        bookTitle={userProfile.currentBook}
        bookAuthor={userProfile.bookAuthor}
        bookDescription={userProfile.bookDescription}
        currentBookHeading="Читаю сейчас"
        aboutSectionHeading="Обо мне"
      />
      {canEdit && (
        <div className="edit-profile-button-container">
          <button
            onClick={() => navigate(`/users/${userId}/edit`)}
            className="edit-profile-button"
          >
            Редактировать профиль
          </button>

          {/* <button
            onClick={() => navigate('/link-nfc')}
            className="link-nfc-button"
          >
            Моя сатистика чтения
          </button> */}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default UserProfilePage;
