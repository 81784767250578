import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ClubsCatalogPage from './pages/ClubsCatalogPage';
import ClubPage from './pages/ClubPage';
import EventsCatalogPage from './pages/EventsCatalogPage';
import EventPage from './pages/EventPage';
import AuthPage from './pages/AuthPage';
import ProfilePage from './pages/ProfilePage'
import ProfileEditPage from './pages/ProfileEditPage';
import EventDetails from './pages/EventDetails'; // Import the new component
import LinkNfcPage from  './pages/LinkNfcPage';
import ScanTagPage from './pages/ScanTagPage';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/clubs" element={<ClubsCatalogPage />} />
      <Route path="/clubs/:clubId" element={<ClubPage />} />
      <Route path="/events" element={<EventsCatalogPage />} />
      <Route path="/events/:eventId" element={<EventPage />} />
      {/* <Route path="/login" element={<AuthPage isLogin={true} />} />
      <Route path="/register" element={<AuthPage isLogin={false} />} /> */}
      <Route path="/auth/:authType" element={<AuthPage />} /> {/* Маршрут для авторизации и регистрации */}
      <Route path="/users/:userId" element={<ProfilePage />} />
      <Route path="/users/:userId/edit" element={<ProfileEditPage />} />
      <Route path="/event-details" element={<EventDetails />} />
      <Route path="/link-nfc" element={<LinkNfcPage />} />
      <Route path="/scan-tag" element={<ScanTagPage />} />

    </Routes>
  </Router>
);

export default App;
