// src/pages/AuthPage.jsx
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom'; // Импортируем useParams
import Navbar from '../components/common/Navbar';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import Footer from '../components/common/Footer';

const AuthPage = () => {
  const { authType } = useParams(); // Получаем параметр из URL
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('return_url');

  // Проверяем, на какой странице находится пользователь
  const isLogin = authType === 'login'; // Если URL содержит 'login', показываем компонент Login

  return (
    <>
      <Navbar />
      {isLogin ? <Login returnUrl={returnUrl} /> : <Register returnUrl={returnUrl} />}
      <Footer />
    </>
  );
};

export default AuthPage;
